
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Effect } from '../api/src/models/effect';

import actionMixin from '../mixins/action';
import effectsMixin from '../mixins/effects';
import uiMixin from '../mixins/ui';

import ActionCard from './action-card';
import IdeaQuickEntry from './idea-quick-entry';

export default {
  name: 'ActionFocus',
  components: {
    ActionCard,
    IdeaQuickEntry,
  },
  mixins: [
    actionMixin,
    effectsMixin,
    uiMixin,
  ],
  data() {
    return {
      defaultEffectName: Effect.EffectName.enum.Completion,
    };
  },
  computed: {
    actionId() {
      return this.$store.state.actionFocus.action ? this.$store.state.actionFocus.action.id : undefined;
    },
    hasFocusCountdown() {
      return typeof this.$store.state.actionFocus.countdownInterval !== 'undefined';
    },
    countdown() {
      return this.$store.state.actionFocus.countdown;
    },
    defaultEffect() {
      return this.effects.find(ef => ef.name === this.defaultEffectName);
    },
  },
  watch: {
    countdown() {
      if (this.countdown > 1) {
        Haptics.impact({ style: ImpactStyle.Light });
      } else if (this.countdown === 1) {
        Haptics.vibrate();
      }
    },
  },
  mounted() {
    this.$bindKeys.bind('action-focus', {}, true);
  },
  destroyed() {
    this.$bindKeys.unbind('action-focus');
  },
  methods: {
    handleFocusStopCountdown() {
      this.$store.dispatch('actionFocus/stopAllIntervals');
    },
    handleFocusStop() {
      return this.$store.dispatch('actionFocus/stopProgress');
    },
    async handleFocusStopAndEffect() {
      await this.effectPrompt(this.defaultEffect, { itemId: this.actionId, skipConfirm: true });
      await this.handleFocusStop();
    },
  },
};
